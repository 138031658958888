export const joinClasses = (...classNames) => {
  return classNames.filter((val) => val).join(" ");
};

export const getHorizontalAlign = (horizontalAlign) => {
  return `justify-content-md-${horizontalAlign || "center"}`;
};

export const getVerticalAlign = (verticalAlign) => {
  return verticalAlign ? `align-items-md-${verticalAlign}` : "";
};

export const calculateSpacing = (fields) => {
  return joinClasses(
    fields["margin-bottom"]?.value && `mb-${fields["margin-bottom"].value}`,
    fields["margin-top"]?.value && `mt-${fields["margin-top"].value}`,
    fields["padding-bottom"]?.value && `pb-${fields["padding-bottom"].value}`,
    fields["padding-top"]?.value && `pt-${fields["padding-top"].value}`,
  );
};
